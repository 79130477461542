
<template>
  <div class="mainTitle" :style="{opacity: lucency}">
    <van-icon name="arrow-left" @click="backFn" class="backBtn" />
    <span class="text">{{$t('documentTitle')}}</span>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "mainTitle",
  props: ["lucency"],
  computed: {
    backurl() {
      return this.common.backUrl;
    },
    ...mapState(["common"])
  },
  methods: {
    backFn() {
      location.href = this.backurl + `/index.html#/`;
    }
  }
};
</script>

<style lang="scss">
.mainTitle {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  height: 44px;
  padding: 0 17px;
  line-height: 44px;
  text-align: center;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  background: #D80709;
  color: #ffffff;
  .backBtn {
    position: absolute;
    left: 14px;
    line-height: 44px;
    font-size: 20px;
  }
}
</style>
