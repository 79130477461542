<template>
  <div :class="['mainHeader', { enHeader: isEn || isVn }]" :style="{ height: headerHeight + 'px' }">
    <!-- <img
        :src="require('@/assets/imgs/comm/headLogo.png')"
        class="logo"
        alt=""
    />-->
    <div class="content">
      <div class="countDown">
        <span>{{$t("home.Final")+' '+$t("home.conntdown")}}:</span>
        <div class="time">{{ day[0] }}</div>
        <div class="time">{{ day[1] }}</div>
        <span>{{ $t("home.Day") }}</span>
        <div class="time">{{ hour[0] }}</div>
        <div class="time">{{ hour[1] }}</div>
        <span>{{ $t("home.Hour") }}</span>
        <div class="time">{{ minute[0] }}</div>
        <div class="time">{{ minute[1] }}</div>
        <span>{{ $t("home.Minute") }}</span>
        <!-- <div class="time">{{ second[0] }}</div>
        <div class="time">{{ second[1] }}</div>
        <span>{{ $t("home.Second") }}</span>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainHeader",
  props: ["headerHeight"],
  data() {
    return {
      day: [0, 0],
      hour: [0, 0],
      minute: [0, 0],
      second: [0, 0],
      countDownTimer: null
    };
  },
  methods: {
    countDownDate() {
      let d = new Date();
      let len = d.getTime();
      let offset = d.getTimezoneOffset() * 60000;
      let utcTime = len + offset;
      // 获取固定东八区的时间
      let now = new Date(utcTime + 3600000 * 8);
      let endDate = new Date("2024/7/15 8:00:00");
      let leftTime = endDate.getTime() - now.getTime(); //计算剩余的毫秒数

      if (leftTime <= 0) {
        leftTime = 0;
      }
      let leftsecond = parseInt(leftTime / 1000); //计算剩余的秒数
      let day = Math.floor(leftsecond / (60 * 60 * 24));
      let hour = Math.floor((leftsecond - day * 24 * 60 * 60) / 3600);
      let minute = Math.floor(
        (leftsecond - day * 24 * 60 * 60 - hour * 3600) / 60
      );
      let second = Math.floor((leftTime / 1000) % 60, 10);
      day = (day + "").length > 1 ? day + "" : "0" + day;
      hour = (hour + "").length > 1 ? hour + "" : "0" + hour;
      minute = (minute + "").length > 1 ? minute + "" : "0" + minute;
      second = (second + "").length > 1 ? second + "" : "0" + second;
      this.day = [day[0], day[1]];
      this.hour = [hour[0], hour[1]];
      this.minute = [minute[0], minute[1]];
      this.second = [second[0], second[1]];
    },
    startCountDown() {
      this.countDownTimer = setInterval(this.countDownDate, 1000);
    }
  },
  computed: {
    isEn() {
      return this.$i18n.locale == "en";
    },
    isVn() {
      return this.$i18n.locale == "vn";
    }
  },
  created() {
    this.startCountDown();
  },
  destroyed() {
    clearInterval(this.countDownTimer);
    this.countDownTimer = null;
  }
};
</script>

<style lang="scss" scoped>
.mainHeader {
  width: 100%;
  height: 200px;
  background: url(../../assets/imgs/comm/banner.png) no-repeat;
  background-size: 100% 100%;
  color: #fff;
  margin-bottom: -23px;
  padding-top: 44px;
  position: fixed;
  top: 0;
  &.enHeader {
    background: url(../../assets/imgs/comm/banner-en.png) no-repeat;
    background-size: 100% 100%;
  }
  .content {
        position: absolute;
    display: flex;
    bottom: 33px;
    left: 10px;
    padding: 0 5px;
    background: #00000033;
    border-radius: 100px;
    .timer {
      font-size: 14px;
      line-height: 20px;
    }
    // .title {
    //   width: 320px;
    //   margin-top: 5px;
    // }
    .openTime {
      margin: 5px 0;
      width: 126px;
      margin-top: -8px;
    }
  }
  .countDown {
    display: flex;
    align-items: center;
    justify-content: center;
    .time {
      font-family: "DIN";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: #FF1420;
      text-align: center;
      overflow: hidden;
      margin-top: 3px;
    }
    span {
      color: #fff;
      font-size: 12px;
      margin: 0 4px;
      white-space: nowrap;
    }
  }
}
</style>
